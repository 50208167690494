/* eslint-disable no-multi-str */
/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    title: "Python / Cloud Automation Engineer", // flexible with title
    company: "Norges Bank Investment Management",
    location: "New York",
    date: "Jan'21 - Present",
    summary:
      "Norges Bank Investment Management (NBIM) manages the world's largest soverign wealth fund with over US$1.39 trillion in assets (2021) and seeks to safeguard the long-term financial interests of Norway's future generations through active management and ownership. I was initially hired into the role of Application Support Analyst to provide a one-stop-shop service to the Business. Through self-taught skills in Python, AWS, and Azure DevOps, I automated systems monitoring and also developed adhoc scripts to apply workarounds to recurring issues due to system deficiencies in a continuous delivery manner. This enabled me to transition to my current Development role where I work directly with the Business in an agile fashion to create solutions that frees up their time and cognitive load so that they can focus on maximizing returns for the fund.",
    bullets: [
      "I am currently working on modernizing the Fixed Income (FI) backend into a Serverless Event-Driven Microservices architecture. This includes creating dedicated services to handle the extraction and transformation of data to reduce code duplication and data inconsistencies. Tech stack: Python, Docker, Lambda, ECR, Eventbridge, DynamoDB, Terraform, Azure DevOps, GitHub",

      // Axes
      "I designed and built a service to extract, clean, and enrich axe data from a vendor's API streaming service. This includes storing the data in a data lake and integrating it with the data warehouse. Tech stack: Python, Docker, Lambda, ECR, S3, SNS, Terraform, Azure DevOps, GitHub.",
      // "I designed and built a series of microservices to extract data from a vendor and display it on our web application. The microservices include an infrastructure pipeline to build and manage our data lake and its integration with our data warehouse, a data pipeline to onboard new datasets in the future and to set up the tables and views in our schema, a Python program to get data from the vendor using their API and upload it to our data lake, and an API for the web application to extract data from the data warehouse. This is done using GitHub, Terraform, Azure DevOps, Python, SQL, and documented using Mermaid.",
      // "I designed and built a pipeline to set up and manage cloud infrastructure for our data lake and its integration with our data warehouse. The infrastructure is deployed using Terraform and documented using Mermaid.",
      // "I created a cloud based application to help Equity Portfolio Managers and Traders automate the full end-to-end flow of IPO security setup. This included an API to allow other internal systems to interact with my service programatically and a CI/CD pipeline to automate the deployment of code updates. This is developed using Python, GitHub, Cloudformation, and Azure DevOps.",
      "I automated the full end-to-end workflow of setting up IPO securities in our systems so that the Equity Porfolio Managers can focus on making investment decisions without having to worry about chasing Operations to get the securities set up. This includes parsing emails, requesting security data using Bloomberg's Hypermedia API (HAPI), calling other internal APIs to load the data into the internal systems, and exposing a simple API to my application. Tech stack: Python, EC2, Azure AD, FastAPI, Lambda, Application Load Balancer, Cloudformation, Azure DevOps, and GitHub.",
      // Previously, the PMs would have to submit a ticket to request the IPO security to be set up. Operations then have to constantly check Bloomberg to see if the data is available. When the data becomes available, Operations will then request the security from Bloomberg and manually flow the data into multiple systems. My automation parses IPO emails from the Traders for the ticker and exchange and constantly check Bloomberg to see if the security data is available through their Hypermedia API (HAPI). Once the data becomes available, my automation will request the data through HAPI and flow it into all the systems. A notification is then sent to a Teams notification channel where the PM will then know that the security is available and that they can go ahead and create their orders in our OMS.
      // sometimes Ops might take a while to pick up the ticket, or some securities might take a while to become available on bloomberg, Ops might only check frequently on the first day. or Ops could forget to flow the data into one of the systems and break the Order/Trade workflow
      // "I also handle any adhoc automation tasks required from the Business - tasks like setting up CI/CD pipelines, scheduling Python scripts, or setting up EC2s to be used as a workstation.",
      "I created a pipeline to deploy Linux EC2 workstations in AWS to do all of my development work as I got tired of having to reconfigure my development environment everytime my laptop gets re-imaged. Several of my business users also requested their own workstation and therefore I have also been performing some light sysadmin work for them. Tech stack: EC2, Route53, Automation Documents, EFS, Cloudformation, Azure DevOps, and GitHub"
    ],
  },
  {
    title: "Application Support Analyst",
    company: "Norges Bank Investment Management",
    location: "New York",
    date: "Aug'17 - Jan'21",
    bullets: [
      "I became the subject matter expert for all business critical systems in the NY office and provided a one-stop white-glove support experience for my business users.",
      "I developed multiple Python scripts to automate data quality checks, system health checks, and send alert notification when issues arise. This enabled me to catch a lot of the issues and resolved them before the business users even noticed.", // cash projection, unconfirmed trades
      "I performed root cause analysis on incidents and proposed solutions to the development teams to fix the underlying issues in order to prevent them from re-occurring.",
      // "I became the subject matter expert for all business critical systems ∏in NY by spending hours digging into logs and tracing the flow of data between systems whenever an incident arose to not only get a better understanding of that system but also how the different systems work together as a whole.",
      // "By becoming the subject matter expert, I was able to provided a one-stop-shop white-glove support experience to the business users. Majority of the incidents were resolved on the spot. However, for issues that needed escalation, I constantly chased down the system managers and developers and kept the business users up to date so they can focus on their tasks without having to worry about unresolved tickets.",
    ], // trader shocked that there was actually a question that i could not answer in 4 years
  },
  {
    title: "IT Service Delivery Analyst (Consultant)",
    company: "National Grid",
    location: "New York",
    date: "Jan'15 - Aug'17",
    summary:
      "National Grid is one of the largest investor-owned energy companies in the world - covering New York, Massachusetts, Rhode Island, and the United Kingdom (2015). I was brought on as an IT Service Delivery Analyst to ensure that an exceptional level of reporting support is provided to my organization as we transition many previously outsourced IT functions back in-house.",
    bullets: [
      "I created an Excel scorecard that consumes data from a SharePoint list and automatically generate some visualizations for monthly KPI reporting.",
      "I helped my organization improve the accuracy and efficiency of our monthly reporting by using Alteryx to eliminate manual, repetitive, Excel manipulations and data cleansing. This allowed them to spend more time performing analyses for valuable insights.",
      "I also helped my organization create clear and interactive data visualizations using Tableau to tell their stories in a simple and easy to understand manner",
    ],
  },
  // {
  //     title: "IT Consultant Trainee",
  //     company: "FDM Group",
  //     location: "New York",
  //     date: "Jun'14 - Dec'14",
  // },
  {
    title: "Indoor Sales Analyst",
    company: "China Airlines",
    location: "New York",
    date: "Jul'12 - Jun'14",
  },
  // {
  //   title: "China Airlines Airport Staff",
  //   company: "J-Blatt Inc",
  //   location: "New York",
  //   date: "May'12 - Jul'12",
  // },
];
