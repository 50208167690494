export default function Bullet(props) {
    // console.log(props)
    // console.log(typeof props)
    const listElements = props.bullets.map(bullet => {
        return <li>{bullet}</li>
    })
    // console.log(listElements)
    return (
        <ul className="work-experience--jobs--job--bullets">
            {listElements}
        </ul>
    )
}