import skillsData from "./skillsData"

export default function Skills() {
    const skillsElement = skillsData.map(skill => {
        return <li>{skill}</li>
    })
    return (
        <div className="skills">
            <h2 className="skills--header section-header">Skills:</h2>
            <div className="skills--skills">
                <ul>
                    {skillsElement}
                </ul>
            </div>
        </div>
    )
}