import Bullet from "./Bullet"

export default function Jobs(props) {
    let summary = null
    if (props.summary) {
        summary = (
            <p className="work-experience--jobs--job--summary">
                {props.summary}
            </p>
        )
    }
    
    return (
        <div className="work-experience--jobs--job">
            <div className="work-experience--jobs--job--info">
                <div className="work-experience--jobs--job--info--headline">
                    <p className="work-experience--jobs--job--info--headline--title">{props.title}</p>
                    <p className="comma-space">,</p>
                    <p className="work-experience--jobs--job--info--headline--company">{props.company}</p>
                    <p className="comma-space">,</p>
                    <p className="work-experience--jobs--job--info--headline--location">{props.location}</p>
                </div>
                <p className="work-experience--jobs--job--info--date">{props.date}</p>
            </div>
            {summary}
            {props.bullets && console.log(props.bullets)}
            {props.bullets && <Bullet bullets={props.bullets} />}
        </div>
    )
}