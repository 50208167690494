import Job from "./job/Job"
import jobData from "./job/jobData"

export default function WorkExperience() {
    const jobElement = jobData.map(job => {
        return <Job 
            title={job.title}
            company={job.company}
            location={job.location}
            date={job.date}
            summary={job.summary}
            bullets={job.bullets} />
    })
    // console.log(jobElement)
    return (
        <div className="work-experience">
            <h2 className="work-experience--header section-header">Work Experience:</h2>
            <div className="work-experience--jobs">
                {jobElement}
            </div>
            
        </div>
    )
}