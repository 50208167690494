/* eslint-disable import/no-anonymous-default-export */
export default [
    "Python",
    "Docker",
    "Git",
    "AWS",
    "Terraform",
    "Cloudformation",
    "Azure DevOps",
    "CI/CD",
    "Agile",
    "Snowflake",
    "SQL",
    "Mermaid",
]