import Education from "./education/Education";
import Skills from "./skills/Skills";
import WorkExperience from "./workExperience/WorkExperience";
import Certifications from "./certifications/Certifications";

export default function Main() {
    return (
        <div className="main">
            <WorkExperience />
            <Certifications />
            <Skills />
            <Education />
        </div>
    )
}