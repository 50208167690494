export default function Education() {
    return (
        <div className="education">
            <h2 className="education--header section-header">Education:</h2>
            <div className="education--schools">
                <div className="education--schools--school">
                    <div className="education--schools--school--info">
                        <p className="education--schools--school--info--name">Dowling College</p>
                        <p className="comma-space">,</p>
                        <p className="education--schools--school--info--location">Oakdale, New York</p>
                    </div>
                    {/* <p className="education--schools--school--date">Graduated Jan'2012</p> */}
                    <p className="education--schools--school--degree">Masters of Business Administration in Aviation Management</p>
                    <p className="education--schools--school--gpa">GPA: 3.8 / 4.0</p>
                </div>
            </div>
            <div className="education--schools">
                <div className="education--schools--school">
                    <div className="education--schools--school--info">
                        <p className="education--schools--school--info--name">SUNY Albany</p>
                        <p className="comma-space">,</p>
                        <p className="education--schools--school--info--location">Albany, New York</p>
                    </div>
                    {/* <p className="education--schools--school--date">Graduated May'2010</p> */}
                    <p className="education--schools--school--degree">Bachelors of Arts in Psychology</p>
                    <p className="education--schools--school--gpa">GPA: 3.6 / 4.0</p>
                    <p className="education--schools--school--honors">Honors: Magna Cum Laude & made multiple Dean's List</p>
                </div>
            </div>
        </div>
    )
}