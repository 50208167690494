import certificationsData from "./certificationsData"

export default function Certifications() {
    const certificationsElement = certificationsData.map(certification => {
        return <li>{certification}</li>
    })
    return (
        <div className="certifications">
            <h2 className="certifications--header section-header">Certifications:</h2>
            <div className="certifications--certifications">
                <ul>
                    {certificationsElement}
                </ul>
            </div>
        </div>
    )
}