export default function Header() {
    return (
        <header className="header">
            <h1 className="header--name">Ping Lin</h1>
            <div className="header--contact-info">
                <div className="header--contact-info--item">
                    <i class="fas fa-envelope"></i>
                    <a
                        href="mailto:pingli88@gmail.com"
                        target="_blank"
                        rel="noreferrer">
                        pingli88@gmail.com</a>
                </div>
                <div className="header--contact-info--item">
                    <i class="fab fa-linkedin"></i>
                    <a
                        href="https://www.linkedin.com/in/pingli88"
                        target="_blank"
                        rel="noreferrer">
                        linkedin.com/in/pingli88</a>
                </div>
                {/* <div className="header--contact-info--item">
                    <i class="fas fa-globe"></i>
                    <a
                        href="https://www.pingsays.me"
                        target="_blank"
                        rel="noreferrer">
                        web resume</a>
                </div> */}
            </div>
            <hr className="header--end"></hr>
        </header>
    )
}